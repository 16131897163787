
import React, { useState, useEffect, useRef } from 'react';
import { TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Container, Typography, Paper, Box } from '@mui/material';

function App() {
  const modernStyle = {
    fontFamily: "Tahoma, sans-serif",
    color: "#333",
    backgroundColor: "#f9f9f9",
    padding: "20px",
    borderRadius: "8px",
  };

  const headerStyle = {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: "20px",
  };
    
  const [text, setText] = useState('');
  const [language, setLanguage] = useState('english');
  const [charCount, setCharCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [charsLeft, setCharsLeft] = useState(0);
  const [charsLeftMax, setCharsLeftMax] = useState(0);

  const textAreaRef = useRef(null);
  const version = "v1.6.1";  // Revert to the earlier stable version
  const maxPages = 5;

  useEffect(() => {
    handleTextChange(text);
  }, [text, language]);

  const handleTextChange = (value) => {
    detectLanguage(value);
    const truncatedValue = Array.from(value).slice(0, language === 'arabic' ? 335 : 750).join('');
    setText(truncatedValue);
    const encodedValue = [...value];  // Handle emojis as single characters
    calculateCounts(encodedValue.join(''));

    if (language === 'arabic' && textAreaRef.current) {
      setTimeout(() => {
        textAreaRef.current.selectionStart = textAreaRef.current.value.length;
        textAreaRef.current.selectionEnd = textAreaRef.current.value.length;
      }, 0);
    }
  };

  const detectLanguage = (value) => {
    const arabicPattern = /[؀-ۿ]/;
    setLanguage(arabicPattern.test(value) ? 'arabic' : 'english');
  };

  const calculateCounts = (value) => {
    const maxShortMessageLength = language === 'arabic' ? 70 : 160;
    const maxLongMessageLength = language === 'arabic' ? 67 : 153;
    const maxTotalChars = language === 'arabic' ? 335 : 750;

    let totalCharCount = calculateCharCount(value);
    let pageCount = totalCharCount <= maxShortMessageLength ? 1 : Math.ceil((totalCharCount - maxShortMessageLength) / maxLongMessageLength) + 1;
    let charsLeft = pageCount === 1 ? maxShortMessageLength - totalCharCount : (pageCount * maxLongMessageLength) - (totalCharCount - maxShortMessageLength);

    setCharCount(totalCharCount);
    setPageCount(pageCount);
    setCharsLeft(pageCount < maxPages ? charsLeft : 0);
    setCharsLeftMax(maxTotalChars - totalCharCount);
  };

  const calculateCharCount = (value) => {
    let count = 0;
    for (let i = 0; i < value.length; i++) {
      let charCode = value.codePointAt(i);
      if (charCode > 0xffff) {
        count += 2;  // Emoji or multi-byte char
        i++;  // Skip the next code unit (for surrogate pairs)
      } else {
        count += 1;
      }
    }
    return count;
  };

  const safeTruncate = (value, maxTotalChars) => {
    let truncatedText = '';
    let totalCharCount = 0;
    for (let i = 0; i < value.length; i++) {
      let charCode = value.codePointAt(i);
      if (totalCharCount + (charCode > 0xffff ? 2 : 1) > maxTotalChars) {
        break;  // Stop when the next character exceeds the max allowed
      }
      truncatedText += value[i];
      totalCharCount += (charCode > 0xffff ? 2 : 1);
      if (charCode > 0xffff) i++;  // Skip surrogate pairs
    }
    return truncatedText;
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
        <Box mb={2} textAlign="center">
          <Typography variant="h4" fontWeight="bold" color="primary">Go2SMS Messages Counter</Typography>
        </Box>

        <FormControl component="fieldset">
          <FormLabel component="legend">Language is auto-detected</FormLabel>
          <RadioGroup
            row
            aria-label="language"
            name="language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <FormControlLabel value="english" control={<Radio />} label="English" />
            <FormControlLabel value="arabic" control={<Radio />} label="Arabic" />
          </RadioGroup>
        </FormControl>

        <TextField style={{ width: "100%", marginBottom: "20px", fontFamily: "Tahoma" }}
          inputRef={textAreaRef}
          multiline
          rows={5}
          fullWidth
          variant="outlined"
          margin="normal"
          value={text}
          onChange={(e) => handleTextChange(e.target.value)}
          inputProps={{
            style: { direction: language === 'arabic' ? 'rtl' : 'ltr', textAlign: language === 'arabic' ? 'right' : 'left' },
          }}
          placeholder="Type or paste your message here..."
        />

        <Box mt={2}>
          <Typography>Characters: {charCount}</Typography>
          <Typography>Pages: {pageCount}</Typography>
          {pageCount < maxPages && <Typography>Characters left for next page: {charsLeft}</Typography>}
          <Typography>Characters left till max messages: {charsLeftMax}</Typography>
        </Box>

        <Box mt={3} textAlign="center">
          <Typography variant="caption" color="textSecondary">Version {version}</Typography>
          <Typography variant="caption" color="textSecondary">© {new Date().getFullYear()} Go2sms.net. All rights reserved.</Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default App;
